import { t } from 'utils/i18n';
import { alpha } from '@mui/material';
import { PENALTY_STATUS } from '@type/financial/salary.type';
export interface IStatus {
  [key: string]: string | number | boolean;
}

export enum SALARY_CALCULATE_TYPE {
  PREPARATION_FEE = 'preparationFee',
  IMAGE_FEE = 'imageFee',
  TUITION_FEE = 'tuitionFee',
  BONUS = 'bonus',
  LEVEL_BONUS = 'levelBonus',
  GROUP_BONUS = 'groupBonus',
}

export enum SALARY_INCREASE_STATUS_ENUM {
  PREPARATION_FEE = 'preparationFee',
  IMAGE_FEE = 'imageFee',
  TUITION_FEE = 'tuitionFee',
  BONUS = 'bonus',
  LEVEL_BONUS = 'levelBonus',
}

export const SALARY_INCREASE_STATUS_TEXT: IStatus = {
  [SALARY_INCREASE_STATUS_ENUM.PREPARATION_FEE]: t(
    'constants.salary.salaryIncreaseStatus.preparationFee',
    '準備費',
  ),
  [SALARY_INCREASE_STATUS_ENUM.IMAGE_FEE]: t(
    'constants.salary.salaryIncreaseStatus.imageFee',
    '形象費',
  ),
  [SALARY_INCREASE_STATUS_ENUM.TUITION_FEE]: t(
    'constants.salary.salaryIncreaseStatus.tuitionFee',
    '授課費',
  ),
  [SALARY_INCREASE_STATUS_ENUM.BONUS]: t(
    'constants.salary.salaryIncreaseStatus.bonus',
    '教材難度加給',
  ),
  [SALARY_INCREASE_STATUS_ENUM.LEVEL_BONUS]: t(
    'constants.salary.salaryIncreaseStatus.levelBonus',
    '等級加給',
  ),
};

export enum SALARY_REDUCE_STATUS_ENUM {
  TEACHER_LATE = 'teacherLate',
  FEEDBACK = 'feedback',
  TEACHER_ABSENTEEISM = 'teacherAbsenteeism',
  TAKE_LEAVE_TOO_LATE = 'takeLeaveTooLate',
}

export const SALARY_REDUCE_STATUS_TEXT: IStatus = {
  [SALARY_REDUCE_STATUS_ENUM.TEACHER_LATE]: t(
    'constants.salary.salaryReduceStatus.teacherLate',
    '教師遲到',
  ),
  [SALARY_REDUCE_STATUS_ENUM.FEEDBACK]: t(
    'constants.salary.salaryReduceStatus.feedback',
    '未填寫課後回饋',
  ),
  [SALARY_REDUCE_STATUS_ENUM.TEACHER_ABSENTEEISM]: t(
    'constants.salary.salaryReduceStatus.teacherAbsenteeism',
    '教師曠課',
  ),
  [SALARY_REDUCE_STATUS_ENUM.TAKE_LEAVE_TOO_LATE]: t(
    'constants.salary.salaryReduceStatus.takeLeaveTooLate',
    '課堂前24小時內請假',
  ),
};

export enum COURSE_STATUS_ENUM {
  FINISHED = 'finished',
  EXCEPTION = 'exception',
  CANCELED = 'cancelled',
  TEACHER_TAKE_LEAVE = 'teacherTakeLeave',
  TEACHER_ABSENTEEISM = 'teacherAbsenteeism',
  STUDENT_ABSENTEEISM = 'studentAbsenteeism',
}

export const COURSE_STATUS_TEXT: IStatus = {
  [COURSE_STATUS_ENUM.FINISHED]: t(
    'constants.salary.courseStatus.finished',
    '正常完課',
  ),
  [COURSE_STATUS_ENUM.EXCEPTION]: t(
    'constants.salary.courseStatus.exception',
    '異常完課',
  ),
  [COURSE_STATUS_ENUM.STUDENT_ABSENTEEISM]: t(
    'constants.salary.courseStatus.studentAbsenteeism',
    '學生曠課',
  ),
  [COURSE_STATUS_ENUM.CANCELED]: t(
    'constants.salary.courseStatus.cancelled',
    '課程取消',
  ),
  [COURSE_STATUS_ENUM.TEACHER_TAKE_LEAVE]: t(
    'constants.salary.courseStatus.teacherTakeLeave',
    '教師請假',
  ),
  [COURSE_STATUS_ENUM.TEACHER_ABSENTEEISM]: t(
    'constants.salary.courseStatus.teacherAbsenteeism',
    '教師曠課',
  ),
};
/** New Salary */
export enum ESalaryType {
  FINISHED = 'finished',
  EXCEPTION = 'exception',
  CANCELED = 'cancelled',
}
export enum EPENALTY_STATUS {
  PENALTY = 'penalty',
  NO_PENALTY = 'noPenalty',
  NONE = 'none',
}

export const PENALTY_STATUS_NAME = {
  [PENALTY_STATUS.PENALTY]: 'Bị xử phạt',

  [PENALTY_STATUS.NORMAL]: 'Không Bị xử phạt',
} as const;

export const EPENALTY_STATUS_CONFIG = {
  [PENALTY_STATUS.PENALTY]: {
    style: {
      borderRadius: '6px',
      backgroundColor: alpha('#FF4842', 0.16),
      color: '#B72136',
      padding: '0 8px',
      fontSize: '12px',
      lineHeight: '20px',
    },
    content: t('constants.salary.penaltyStatusName.disciplinaryAction', '懲處'),
  },
  [PENALTY_STATUS.NORMAL]: {
    style: {
      borderRadius: '6px',
      backgroundColor: alpha('#FFC107', 0.16),
      color: '#B78103',
      padding: '0 8px',
      fontSize: '12px',
      lineHeight: '20px',
    },
    content: t(
      'constants.salary.penaltyStatusName.noDisciplinaryAction',
      '不懲處',
    ),
  },
};
export enum ESALARY_COURSE_ISSUE {
  VIDEO_RECORD_TOO_SHORT = 'videoRecordTooShort', // 錄影太短
  TEACHER_LATE = 'teacherLate', // 教師遲到
  STUDENT_LATE = 'studentLate', // 學生遲到
  TEACHER_LEAVE_EARLY = 'teacherLeaveEarly', // 教師早退
  STUDENT_LEAVE_EARLY = 'studentLeaveEarly', // 學生早退
  TEACHER_TAKE_LEAVE_TOO_LATE = 'teacherTakeLeaveTooLate', // 24時內請假
  TEACHER_ABSENTEEISM = 'teacherAbsenteeism', // 教師曠課
  STUDENT_ABSENTEEISM = 'studentAbsenteeism', // 學生曠課
}

// 拆分堂數狀態
export enum EFeeSplitType {
  FINISHED = 'finished',
  EXCEPTION = 'exception',
  TEACHER_LATE = 'teacherLate',
  TEACHER_ABSENTEEISM = 'teacherAbsenteeism',
  STUDENT_ABSENTEEISM = 'studentAbsenteeism',
}
export enum ESPLIT_ISSUE_OPTION {
  FINISHED = 'finished', // 正常完課
  EXCEPTION = 'exception', // 異常完課
  TEACHER_LATE = 'TEACHER_LATE', // 教師遲到
  TEACHER_ABSENTEEISM = 'TEACHER_ABSENTEEISM', // 教師曠課
  STUDENT_ABSENTEEISM_AND_TEACHER_LATE = 'STUDENT_ABSENTEEISM_AND_TEACHER_LATE', // 學生曠課+教師遲到
  STUDENT_ABSENTEEISM = 'STUDENT_ABSENTEEISM', // 學生曠課
}
export const SPLIT_ISSUE_OPTION_NAME = {
  [ESPLIT_ISSUE_OPTION.FINISHED]: '正常完課',
  [ESPLIT_ISSUE_OPTION.EXCEPTION]: '異常完課',
  [ESPLIT_ISSUE_OPTION.TEACHER_LATE]: '教師遲到',
  [ESPLIT_ISSUE_OPTION.TEACHER_ABSENTEEISM]: '教師曠課',
  [ESPLIT_ISSUE_OPTION.STUDENT_ABSENTEEISM_AND_TEACHER_LATE]:
    '學生曠課+教師遲到',
  [ESPLIT_ISSUE_OPTION.STUDENT_ABSENTEEISM]: '學生曠課',
} as const;

export const SPLIT_ISSUE_OPTION_PARAMS = {
  [ESPLIT_ISSUE_OPTION.FINISHED]: [EFeeSplitType.FINISHED],
  [ESPLIT_ISSUE_OPTION.EXCEPTION]: [EFeeSplitType.EXCEPTION],
  [ESPLIT_ISSUE_OPTION.TEACHER_LATE]: [EFeeSplitType.TEACHER_LATE],
  [ESPLIT_ISSUE_OPTION.TEACHER_ABSENTEEISM]: [
    EFeeSplitType.TEACHER_ABSENTEEISM,
  ],
  [ESPLIT_ISSUE_OPTION.STUDENT_ABSENTEEISM_AND_TEACHER_LATE]: [
    EFeeSplitType.STUDENT_ABSENTEEISM,
    EFeeSplitType.TEACHER_LATE,
  ],
  [ESPLIT_ISSUE_OPTION.STUDENT_ABSENTEEISM]: [
    EFeeSplitType.STUDENT_ABSENTEEISM,
  ],
} as const;

export enum EREDUCE_TYPE {
  TEACHER_LATE = 'teacherLate',
  FEEDBACK = 'feedback',
  TEACHER_ABSENTEEISM = 'teacherAbsenteeism',
  TAKE_LEAVE_TOO_LATE = 'takeLeaveTooLate',
  IMAGE_FEE = 'imageFee',
}
export const REDUCE_TYPE_CONFIG = {
  [EREDUCE_TYPE.TEACHER_LATE]: {
    title: '教師遲到',
    getSubTitle: () => '形象費 *1',
  },
  [EREDUCE_TYPE.FEEDBACK]: {
    title: '課後回饋未填寫',
    getSubTitle: (unit: number) => `上課費 * ${unit}`,
  },
  [EREDUCE_TYPE.TEACHER_ABSENTEEISM]: {
    title: '教師曠課',
    getSubTitle: (unit: number) => `準備費 * ${2 * unit}`,
  },
  [EREDUCE_TYPE.TAKE_LEAVE_TOO_LATE]: {
    title: '教師24小時內請假',
    getSubTitle: (unit: number) => `準備費 * ${unit}`,
  },
  [EREDUCE_TYPE.IMAGE_FEE]: {
    title: '有損公司形象',
    getSubTitle: (unit: number) => `準備費 * ${unit}`,
  },
};
/**
 * @description 加給狀態
 * @property ACTIVE - 啟用
 * @property INACTIVE - 停用
 */
export enum EBONUS_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum EMONEY_TYPE {
  BONUS = 'bonus',
  PENALTY = 'penalty',
}

/**
 * @property all All
 * @property other 其他
 * @property teacherTakeLeave 老師請假
 * @property studentTakeLeave 學生請假(學生取消課程)
 * @property studentAskTransfer 學生要求調課
 * @property studentAskSubstitute 學生要求代課
 * @property studentAskCancel 老師請假 -> 學生要求取消
 * @property teacherAbsenteeism 老師曠課
 * @property teacherLate 老師遲到
 * @property teacherLeaveEarly 老師早退
 * @property studentAbsenteeism 學生曠課
 * @property studentLate 學生遲到
 * @property studentLeaveEarly 學生早退
 * @property tooShortCourse 課程過短
 * @property tooLongCourse 課程超時
 * @property teacherTakeLeaveTooLate 老師於24時內請假
 * @property studentTakeLeaveTooLate 學生於24時內請假
 * @property anomaly 上課時間異常
 * @property videoRecordNotWork 錄影沒有啟動
 * @property videoRecordTooShort 錄影太短
 * @property videoRecordTooLong 錄影太長
 * @property useZoom 使用zoom上課
 * @property afterCourseOverCancel 課後取消
 * @property studentSystemAbnormal 學生系統異常
 * @property teacherSystemAbnormal 老師系統異常
 * @property systemAbnormal 系統異常
 * @property serviceSuspend 服務暫停
 * @property dataError 資料有誤
 * @property courseCancel 課程取消
 * @property FEEDBACK_EMPTY 課後回饋未填寫 (薪資用)
 * @property DAMAGE_COMPANY_IMAGE 有損公司形象 (薪資用)
 */
export enum ESalaryIssue {
  ALL = 'all',
  NONE = 'none',
  OTHER = 'other',
  TEACHER_TAKE_LEAVE = 'teacherTakeLeave',
  STUDENT_TAKE_LEAVE = 'studentTakeLeave',
  STUDENT_ASK_TRANSFER = 'studentAskTransfer',
  STUDENT_ASK_SUBSTITUTE = 'studentAskSubstitute',
  STUDENT_ASK_CANCEL = 'studentAskCancel',
  TEACHER_ABSENTEEISM = 'teacherAbsenteeism',
  TEACHER_LATE = 'teacherLate',
  TEACHER_LEAVE_EARLY = 'teacherLeaveEarly',
  STUDENT_ABSENTEEISM = 'studentAbsenteeism',
  STUDENT_LATE = 'studentLate',
  STUDENT_LEAVE_EARLY = 'studentLeaveEarly',
  TOO_SHORT_COURSE = 'tooShortCourse',
  TOO_LONG_COURSE = 'tooLongCourse',
  TEACHER_TAKE_LEAVE_TOO_LATE = 'teacherTakeLeaveTooLate',
  STUDENT_TAKE_LEAVE_TOO_LATE = 'studentTakeLeaveTooLate',
  ANOMALY = 'anomaly',
  VIDEO_RECORD_NOT_WORK = 'videoRecordNotWork',
  VIDEO_RECORD_TOO_SHORT = 'videoRecordTooShort',
  VIDEO_RECORD_TOO_LONG = 'videoRecordTooLong',
  USE_ZOOM = 'useZoom',
  AFTER_COURSE_OVER_CANCEL = 'afterCourseOverCancel',
  STUDENT_SYSTEM_ABNORMAL = 'studentSystemAbnormal',
  TEACHER_SYSTEM_ABNORMAL = 'teacherSystemAbnormal',
  SYSTEM_ABNORMAL = 'systemAbnormal',
  SERVICE_SUSPEND = 'serviceSuspend',
  DATA_ERROR = 'dataError',
  COURSE_CANCEL = 'courseCancel',
  FEEDBACK_EMPTY = 'feedbackEmpty',
  DAMAGE_COMPANY_IMAGE = 'damageCompanyImage',
  TEACHER_NOT_ACCEPT = 'teacherNotAccept',
  TEACHER_FINISH_COURSE_EARLY = 'teacherFinishCourseEarly',
  TEACHER_JOIN_BUT_NO_STUDENT = 'teacherJoinButNoStudent',
  BOTH_ABSENTEEISM = 'bothAbsenteeism',

  // The teacher announced to be late
  TEACHER_ANNOUNCED_LATE = 'teacherAnnouncedLate',

  // The abnormal system is too long
  TEACHER_SYSTEM_ABNORMAL_TOO_LONG = 'teacherSystemAbnormalTooLong',

  // Teacher do their own work
  TEACHER_DO_THEIR_OWN_WORK = 'teacherDoTheirOwnWork',

  // Teacher does not set the teaching schedule
  TEACHER_NOT_SET_SCHEDULE = 'teacherNotSetSchedule',

  // Teacher does not set the teaching schedule Unavailable
  TEACHER_NOT_SET_SCHEDULE_UNAVAILABLE = 'teacherNotSetScheduleUnavailable',

  // The teacher refused to accept class
  TEACHER_REFUSED_TO_ACCEPT = 'teacherRefusedToAccept',

  // Inappropriate teaching images
  INAPPROPRIATE_TEACHING_IMAGE = 'inappropriateTeachingImages',

  // The outfit is not correct
  OUTFIT_NOT_CORRECT = 'outfitNotCorrect',

  // The teacher arbitrarily changed the schedule
  TEACHER_ARBITRARILY_CHANGED_SCHEDULE = 'teacherArbitrarilyChangedSchedule',

  // The teacher arbitrarily click Ask for leave
  TEACHER_ARBITRARILY_ASK_FOR_LEAVE = 'teacherArbitrarilyAskForLeave',

  // The teacher does not meet the requirements
  TEACHER_NOT_MEET_REQUIREMENTS = 'teacherNotMeetRequirements',

  // Teacher writes a sketchy feedback
  TEACHER_WRITE_SKETCHY_FEEDBACK = 'teacherWriteSketchyFeedback',

  // Contact information exchange
  CONTACT_INFORMATION_EXCHANGE = 'contactInformationExchange',

  // Write a late feedback
  FEEDBACK_LATE = 'feedbackLate',

  // No response to learning materials when learning is wrong
  NO_RESPONSE_LEARNING_MATERIAL = 'noResponseLearningMaterial',

  // Teachers do not comply with the regulations
  TEACHER_NOT_COMPLY_REGULATION = 'teacherNotComplyRegulation',

  // Teachers do not understand the work system
  TEACHER_NOT_UNDERSTAND_SYSTEM = 'teacherNotUnderstandSystem',

  // Teacher doesn't webcam
  TEACHER_NO_WEBCAM = 'teacherNoWebcam',
}

export enum ESalalyIssueFilter {
  TEACHER_LATE = 'teacherLate',
  TEACHER_TAKE_LEAVE = 'teacherTakeLeave',
  STUDENT_TAKE_LEAVE = 'studentTakeLeave',
  TEACHER_TAKE_LEAVE_TOO_LATE = 'teacherTakeLeaveTooLate',
  TEACHER_ABSENTEEISM = 'teacherAbsenteeism',
  STUDENT_ABSENTEEISM = 'studentAbsenteeism',
  TEACHER_NOT_ACCEPT = 'teacherNotAccept',
  TEACHER_FINISH_COURSE_EARLY = 'teacherFinishCourseEarly',
  FEEDBACK_EMPTY = 'feedbackEmpty',
}

export const SALARY_ISSUE_TEXT: Record<string, string> = {
  [ESalaryIssue.NONE]: t('constants.salary.salaryIssueText.none', '無'),
  [ESalaryIssue.OTHER]: t('constants.salary.salaryIssueText.other', '其他'),
  [ESalaryIssue.TEACHER_TAKE_LEAVE]: t(
    'constants.salary.salaryIssueText.teacherTakeLeave',
    '老師請假',
  ),
  [ESalaryIssue.STUDENT_TAKE_LEAVE]: t(
    'constants.salary.salaryIssueText.studentTakeLeave',
    '學生請假（學生取消課程）',
  ),
  [ESalaryIssue.STUDENT_ASK_TRANSFER]: t(
    'constants.salary.salaryIssueText.studentAskTransfer',
    '學生要求調課',
  ),
  [ESalaryIssue.STUDENT_ASK_SUBSTITUTE]: t(
    'constants.salary.salaryIssueText.studentAskSubstitute',
    '學生要求代課',
  ),
  [ESalaryIssue.STUDENT_ASK_CANCEL]: t(
    'constants.salary.salaryIssueText.studentAskCancel',
    '老師請假 -> 學生要求取消',
  ),
  [ESalaryIssue.TEACHER_ABSENTEEISM]: t(
    'constants.salary.salaryIssueText.teacherAbsenteeism',
    '老師曠課',
  ),
  [ESalaryIssue.TEACHER_LATE]: t(
    'constants.salary.salaryIssueText.teacherLate',
    '老師遲到',
  ),
  [ESalaryIssue.TEACHER_LEAVE_EARLY]: t(
    'constants.salary.salaryIssueText.teacherLeaveEarly',
    '老師早退',
  ),
  [ESalaryIssue.STUDENT_ABSENTEEISM]: t(
    'constants.salary.salaryIssueText.studentAbsenteeism',
    '學生曠課',
  ),
  [ESalaryIssue.STUDENT_LATE]: t(
    'constants.salary.salaryIssueText.studentLate',
    '學生遲到',
  ),
  [ESalaryIssue.STUDENT_LEAVE_EARLY]: t(
    'constants.salary.salaryIssueText.studentLeaveEarly',
    '學生早退',
  ),
  [ESalaryIssue.TOO_SHORT_COURSE]: t(
    'constants.salary.salaryIssueText.tooShortCourse',
    '課程過短',
  ),
  [ESalaryIssue.TOO_LONG_COURSE]: t(
    'constants.salary.salaryIssueText.tooLongCourse',
    '課程超時',
  ),
  [ESalaryIssue.TEACHER_TAKE_LEAVE_TOO_LATE]: t(
    'constants.salary.salaryIssueText.teacherTakeLeaveTooLate',
    '老師於24小時內請假',
  ),
  [ESalaryIssue.STUDENT_TAKE_LEAVE_TOO_LATE]: t(
    'constants.salary.salaryIssueText.studentTakeLeaveTooLate',
    '學生於24小時內請假',
  ),
  [ESalaryIssue.ANOMALY]: t(
    'constants.salary.salaryIssueText.anomaly',
    '上課時間異常',
  ),
  [ESalaryIssue.VIDEO_RECORD_NOT_WORK]: t(
    'constants.salary.salaryIssueText.videoRecordNotWork',
    '錄影未啟動',
  ),
  [ESalaryIssue.VIDEO_RECORD_TOO_SHORT]: t(
    'constants.salary.salaryIssueText.videoRecordTooShort',
    '錄影過短',
  ),
  [ESalaryIssue.VIDEO_RECORD_TOO_LONG]: t(
    'constants.salary.salaryIssueText.videoRecordTooLong',
    '錄影過長',
  ),
  [ESalaryIssue.USE_ZOOM]: t(
    'constants.salary.salaryIssueText.useZoom',
    '使用Zoom上課',
  ),
  [ESalaryIssue.AFTER_COURSE_OVER_CANCEL]: t(
    'constants.salary.salaryIssueText.afterCourseOverCancel',
    '課後取消',
  ),
  [ESalaryIssue.STUDENT_SYSTEM_ABNORMAL]: t(
    'constants.salary.salaryIssueText.studentSystemAbnormal',
    '學生系統異常',
  ),
  [ESalaryIssue.TEACHER_SYSTEM_ABNORMAL]: t(
    'constants.salary.salaryIssueText.teacherSystemAbnormal',
    '老師系統異常',
  ),
  [ESalaryIssue.SYSTEM_ABNORMAL]: t(
    'constants.salary.salaryIssueText.systemAbnormal',
    '系統異常',
  ),
  [ESalaryIssue.SERVICE_SUSPEND]: t(
    'constants.salary.salaryIssueText.serviceSuspend',
    '服務暫停',
  ),
  [ESalaryIssue.DATA_ERROR]: t(
    'constants.salary.salaryIssueText.dataError',
    '資料錯誤',
  ),
  [ESalaryIssue.COURSE_CANCEL]: t(
    'constants.salary.salaryIssueText.courseCancel',
    '課程取消',
  ),
  [ESalaryIssue.FEEDBACK_EMPTY]: t(
    'constants.salary.salaryIssueText.feedbackEmpty',
    '課後回饋未填寫',
  ),
  [ESalaryIssue.DAMAGE_COMPANY_IMAGE]: t(
    'constants.salary.salaryIssueText.damageCompanyImage',
    '損害公司形象',
  ),
};
