import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
// @mui
import {
  useResponsive,
  useCollapseDrawer,
  cssStyles,
  Scrollbar,
  NavSectionVertical,
  Image,
  Mui,
} from '@onedesign/ui';
import logoImg from 'assets/images/logo.svg';
import enLogoImg from 'assets/images/logo-en.svg';
// change icon base on language system
import { changeByLanguageSystem, SwitchLanguageValueType } from 'utils/logo';
import { NAVBAR } from '../../../config';
// config
import navConfig from './NavConfig';
// import NavbarDocs from './NavbarDocs';
// import NavbarAccount from './NavbarAccount';
import CollapseButton from './CollapseButton';
import logoIcon from '@/favicon.svg';

const { Stack, Drawer, Box } = Mui;

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
  loginUser: any;
};

export default function NavbarVertical({
  isOpenSidebar,
  onCloseSidebar,
  loginUser,
}: Props) {
  const theme = useTheme();
  const { pathname } = useLocation();
  const isDesktop = useResponsive('up', 'lg');
  // const isLargeDesktopSize = useResponsive('up', 'xl');
  const icons: SwitchLanguageValueType<string> = {
    logoZh: logoImg,
    logoEn: enLogoImg,
  };

  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onToggleCollapse,
    onHoverEnter,
    onHoverLeave,
  } = useCollapseDrawer();

  // useEffect(() => {
  //   if (isLargeDesktopSize) {
  //     if (isCollapse) {
  //       onToggleCollapse();
  //     }
  //   } else if (isDesktop) {
  //     if (!isCollapse) {
  //       onToggleCollapse();
  //     }
  //   }
  // }, [isDesktop, isLargeDesktopSize, isCollapse, onToggleCollapse]);

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        backgroundColor: '#fff',
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          pr: 2.5,
          pl: 3,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box width={isCollapse ? '100%' : '70%'}>
            <Image
              src={
                isCollapse ? logoIcon : changeByLanguageSystem<string>(icons)
              }
              alt={
                isCollapse ? logoIcon : changeByLanguageSystem<string>(icons)
              }
            />
          </Box>
          {isDesktop && !isCollapse && (
            <CollapseButton
              onToggleCollapse={onToggleCollapse}
              collapseClick={collapseClick}
            />
          )}
        </Stack>

        {/* <NavbarAccount isCollapse={isCollapse} /> */}
      </Stack>

      <NavSectionVertical
        navConfig={navConfig(loginUser)}
        isCollapse={isCollapse}
      />

      {/* <Box sx={{ flexGrow: 1 }} /> */}

      {/* {!isCollapse && <NavbarDocs />} */}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse
            ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH
            : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: 'dashed',
              bgcolor: 'background.default',
              transition: theme =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: theme => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
