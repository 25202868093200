import { STAFF_TYPE_ENUM } from 'constants/teacher.constants';
import { SORT_TYPE } from 'constants/common';
import {
  EFeeSplitType,
  EPENALTY_STATUS,
  EREDUCE_TYPE,
  ESPLIT_ISSUE_OPTION,
  ESalaryIssue,
  ESalaryType,
  SALARY_CALCULATE_TYPE,
} from 'constants/salary.constants';
import {
  ECOURSE_TYPE,
  ECOURSE_TYPE_FILTER,
  ECourseFeedbackStatus,
  EIssue,
} from 'constants/course.constants';
import { EBatchScheduledCourseModifyType } from 'constants/batchScheduledCourse.constants';
import { ITag } from '../tag.type';
import { IStudent } from '../course.type';
import { ITeacher } from '../teacher.type';

export enum PENALTY_STATUS {
  NORMAL = '0', // 不懲處
  PENALTY = '1', // 懲處
}

export enum BREACH_PENALTY_STATUS {
  NORMAL = 0, // 不懲處
  PENALTY = 1, // 懲處
}

export enum REVIEW_ISSUE_STATUS {
  FINISH_CLASS = 0, // 正常完課
  ABNORMAL_CLASS = 1, // 異常完課
}

export enum SALARY_SORT_KEY {
  COURSE_TIME = 'courseTime',
  COURSE_RESULT = 'courseResult',
}

export enum CURRENCY {
  TWD = 'TWD',
  USD = 'USD',
  VND = 'VND',
}

export enum SALARY_LIST_SEARCH_TYPE {
  TEACHER_NAME = 'teacherName',
  TEACHER_STAFF_ID = 'teacherStaffId',
  TEACHER_ONECLUBID = 'teacherOneClubId',
}

export interface IGetAllSalaryListParams {
  skip: number;
  limit: number;
  startAt: string;
  endAt: string;
  searchType?: SALARY_LIST_SEARCH_TYPE;
  keyword?: string;
  staffType?: STAFF_TYPE_ENUM;
}

export interface ITeacherSalary
  extends Pick<
    ITeacher,
    | 'id'
    | 'firstName'
    | 'lastName'
    | 'fullName'
    | 'oneClubId'
    | 'level'
    | 'nickName'
  > {
  teacherStaffId: string;
  staffTypeInfo: {
    staffType: STAFF_TYPE_ENUM;
    staffTypeName: string;
  };
  receivable: string;
  incomeTax: string;
  salaryBeforeTax: string;
}

export interface IGetAllSalaryListResponse {
  total: number;
  teacherSalary: ITeacherSalary[];
}

export interface IGetDetailSalaryListParams {
  skip: number;
  limit: number;
  teacherId?: string;
  penaltyStatus?: PENALTY_STATUS.NORMAL | PENALTY_STATUS.PENALTY;
  startAt: string;
  endAt: string;
  sortType?: SORT_TYPE.ASC | SORT_TYPE.DESC;
  sortKey?: SALARY_SORT_KEY.COURSE_TIME | SALARY_SORT_KEY.COURSE_RESULT;
  issueGroup?: ESalaryIssue[]; // 不帶就是正常完課
  transferCourseType?: ECOURSE_TYPE_FILTER[];
  teacherName?: string;
  isAudition?: boolean | undefined;
  studentName?: string;
  reviewIssue?:
    | REVIEW_ISSUE_STATUS.ABNORMAL_CLASS
    | REVIEW_ISSUE_STATUS.FINISH_CLASS;
}

interface ICreditDetailAction {
  calculateType: SALARY_CALCULATE_TYPE;
  isPositive: boolean; // 是否為正數
  calculateCount: number; // 計算次數
  point: number;
  pointType: 'credit' | 'fixed';
}

export interface ICreditDetail {
  issueGroup: ESalaryIssue[];
  action: ICreditDetailAction[];
  total: string;
}

export interface ICurrencyRecord {
  currency: CURRENCY.TWD | CURRENCY.USD;
  amount: string;
}

interface ISalaryTeacherDetail {
  id: string;
  fullName: string;
  nickName: string;
  firstName: string;
  lastName: string;
  oneClubId: string;
  staffType: STAFF_TYPE_ENUM;
  teacherStaffId: string;
}

export interface ISalaryCourseDetail {
  students: { name: string }[];
  tags: { name: string }[];
  id: string;
  startAt: string;
  endAt: string;
  type: ECOURSE_TYPE;
  isAudition: boolean;
  intervalStatus: string;
}

export interface IDetailSalary {
  creditDetail: ICreditDetail[];
  accountStandardMoneyDeduction: ICurrencyRecord[]; // 本幣扣薪
  accountStandardMoneyPay: ICurrencyRecord[]; // 本幣給薪
  foreignCurrencyDeduction: ICurrencyRecord[]; // 外幣扣薪
  foreignCurrencyPay: ICurrencyRecord[]; // 外幣給薪
  extraBonusAccountStandardMoney: ICurrencyRecord[]; // 本幣額外加給
  extraBonusForeignCurrency: ICurrencyRecord[]; // 外幣額外加給
  extraDeductAccountStandardMoney: ICurrencyRecord[]; // 本幣額外扣項
  extraDeductForeignCurrency: ICurrencyRecord[]; // 外幣額外扣項
  issueGroup: ESalaryIssue[];
  id: string;
  teacher: ISalaryTeacherDetail;
  course: ISalaryCourseDetail;
  receivable: string;
  preparationBaseFee: string;
  imageBaseFee: string;
  tuitionBaseFee: string;
  bonusBaseFee: string;
  levelBaseBonus: string;
  groupBaseBonus: string;
  penaltyStatusInfo: {
    penaltyStatus: PENALTY_STATUS.NORMAL | PENALTY_STATUS.PENALTY;
    penaltyStatusName: '不懲處' | '懲處';
  };
  reviewIssueInfo: {
    reviewIssue:
      | REVIEW_ISSUE_STATUS.FINISH_CLASS
      | REVIEW_ISSUE_STATUS.ABNORMAL_CLASS;
    reviewIssueName: '正常完課' | '異常完課';
  };
  isReviewed: boolean; // 是否已經編輯過
  isScheduled: boolean; // 是否排班(內部老師如果有排班就不支薪)
}

export interface IGetDetailSalaryListResponse {
  total: number;
  salary: IDetailSalary[];
}

export interface IGetAllTeacherSalaryTotalParams {
  startAt: string;
  endAt: string;
  teacherId: string;
}

export interface ITeacherSalaryTotal
  extends Pick<
    ITeacher,
    | 'id'
    | 'firstName'
    | 'lastName'
    | 'fullName'
    | 'oneClubId'
    | 'level'
    | 'staffType'
  > {
  level: number;
  teacherStaffId: string;
}

export interface IGetAlIGetAllTeacherSalaryTotalResponse {
  total: string;
  courseSalary: string;
  bonus: string;
  penalty: string;
  teacher: ITeacherSalaryTotal;
}

export interface ISalary {
  bankAccount: string;
  bankName: string;
  count: number;
  firstName: string;
  id: string;
  lastName: string;
  nickName: string;
  oneClubId: string;
  teacherStaffID: string;
  total: number;
}
/**
 * @description 拆分薪資資料
 * @param reason 拆分薪資原因
 * @param fee 拆分薪資金額
 */
interface IFeeSplit {
  reasons: EFeeSplitType[];
  fee: number;
}
interface IPresentFeeRecord {
  preparationFee: number;
  imageFee: number;
  tuitionFee: number;
  bonus: number;
  levelBonus: number;
  groupBonus: number;
}
/**
 * @description 課程資訊
 * @param salaryIssueGroup 薪資異常(第一次編輯以後的異常)
 * @param issueGroup 課程異常
 * @param id 課程id
 * @param name 課程名稱
 * @param feedbackStatus 課程回饋狀態
 * @param isAudition 是否試聽
 * @param isSalaryEdited 是否編輯過薪資
 */
export interface ICourse {
  salaryIssueGroup: EIssue[];
  issueGroup: EIssue[];
  id: string;
  name: string;
  feedbackStatus: ECourseFeedbackStatus;
  isAudition: boolean;
  isSalaryEdited: boolean;
}
/**
 * @description 前次編輯
 * @param increase 加薪
 * @param reduce 扣薪
 * @param reason 原因
 * @param courseResult 課程結果
 */
interface IPrevEdit {
  increase: string[];
  reduce: string[];
  reason: string;
  courseResult: ESalaryType;
}
/**
 * @description 拆分薪資
 * @param id 拆分薪資id
 * @param reason 拆分薪資原因
 * @param feeOfUnit 拆分薪資單位
 * @param fee 拆分薪資金額
 * @param rate 拆分薪資比例, 例如: 課堂單位 25 * 2.5，拆分後薪資比例就是 [1, 1, 0.5]
 */
export interface ISplitSalary {
  id: string;
  reason: ESPLIT_ISSUE_OPTION;
  feeOfUnit: number;
  fee: number;
  rate: number;
}
/**
 * @description 薪資明細
 * @param students 學生
 * @param tags 課程標籤
 * @param id 薪資id
 * @param name 課程名稱
 * @param course 課程資訊
 * @param courseIssueGroup 課程異常
 * @param teacher 老師資訊
 * @param feeSplit 薪資拆分
 * @param courseStatus 課程狀態
 * @param courseStartAt 課程開始時間
 * @param courseEndAt 課程結束時間
 * @param receivable 應收
 * @param preparationFee 準備費
 * @param imageFee 形象費
 * @param tuitionFee 教材費
 * @param bonus 教材難度加給
 * @param levelBonus 等級加給
 * @param groupBonus 團班加給
 * @param extraBonus 額外加給
 * @param presentFeeRecord 課堂單位費用
 * @param updatedAt 更新時間
 * @param createdAt 建立時間
 * @param salaryType 薪資類型
 * @param courseType 課程類型
 * @param courseCount 課程數量
 * @param prevEdit 前次編輯
 * @param status 薪資懲處狀態
 */
export interface ISalaryDetail {
  students: Omit<IStudent, 'groupId' | 'isUseZoom'>[];
  tags: ITag[];
  id: string;
  name: string;
  course: ICourse;
  courseIssueGroup: EIssue[];
  teacher: Pick<
    ITeacher,
    'id' | 'nickName' | 'firstName' | 'lastName' | 'teacherStaffID'
  >;
  feeSplit: IFeeSplit[];
  courseStatus: string;
  courseStartAt: string;
  courseEndAt: string;
  receivable: number; // 加總
  preparationFee: number; // 準備費
  imageFee: number; // 形象費
  tuitionFee: number; // 教材費
  bonus: number; // 教材難度加給
  levelBonus: number; // 等級加給
  groupBonus: number; // 團班加給
  extraBonus: number; // 額外加給
  presentFeeRecord: IPresentFeeRecord;
  updatedAt: Date | string;
  createdAt: Date | string;
  salaryType: string;
  courseType: string;
  courseCount: number;
  prevEdit: IPrevEdit;
  status: EPENALTY_STATUS;
}

export interface ISalaryDetailPayload {
  reduce: EREDUCE_TYPE[];
  reason: string;
  courseResult: string;
  editor: {
    staffNumber: string;
    name: string;
  };
  status: EPENALTY_STATUS;
  feeSplit: IFeeSplit[];
  extraBonus?: number;
}

export interface ICalculateDetails {
  calculateType: SALARY_CALCULATE_TYPE;
  calculateTypeName: string;
  count: number;
}

export interface ISalaryStatement {
  calculateDetails: ICalculateDetails[];
  isNoSalary: boolean; // 是否不給薪
  total: string;
}

export interface IBreachContractStatement {
  calculateDetails: ICalculateDetails[];
  isEnable: boolean; // 是否啟用此扣薪項目
  issue: ESalaryIssue;
  totalDeductionAmount: string;
}

export interface IFinishedClassInfo {
  issueGroups: { issueGroup: ESalaryIssue[] }[];
  status: REVIEW_ISSUE_STATUS;
  statusName: '正常完課' | '異常完課';
}

export interface ITeachingDetail {
  totalMinutes: number;
  skuMinutes: number;
  count: number;
}

export interface IGetTeacherSalaryDetailResponse {
  salaryStatement: ISalaryStatement[];
  breachContractStatement: IBreachContractStatement[];
  accountStandardMoneyDeduction: ICurrencyRecord[];
  accountStandardMoneyPay: ICurrencyRecord[];
  foreignCurrencyDeduction: ICurrencyRecord[];
  foreignCurrencyPay: ICurrencyRecord[];
  extraBonusAccountStandardMoney: ICurrencyRecord[];
  extraBonusForeignCurrency: ICurrencyRecord[];
  extraDeductAccountStandardMoney: ICurrencyRecord[];
  extraDeductForeignCurrency: ICurrencyRecord[];
  finishedClassInfo: IFinishedClassInfo;
  isScheduled: boolean; // 是否排班時段不計薪
  teachingDetail: ITeachingDetail;
  salaryId: string;
  penaltyStatus: BREACH_PENALTY_STATUS.NORMAL | BREACH_PENALTY_STATUS.PENALTY;
  isReviewed: boolean; // 是否編輯過,
  receivable: string;
}

export interface IPatchBreachContractStatement {
  isEnable: boolean;
  issue: ESalaryIssue;
}

export interface IPatchTeacherSalaryDetailPayload {
  accountStandardMoneyDeduction: ICurrencyRecord[];
  accountStandardMoneyPay: ICurrencyRecord[];
  foreignCurrencyDeduction: ICurrencyRecord[];
  foreignCurrencyPay: ICurrencyRecord[];
  extraBonusAccountStandardMoney: ICurrencyRecord[];
  extraBonusForeignCurrency: ICurrencyRecord[];
  extraDeductAccountStandardMoney: ICurrencyRecord[];
  extraDeductForeignCurrency: ICurrencyRecord[];
  breachContractStatement: IPatchBreachContractStatement[];
  remark: string;
  penaltyStatus: BREACH_PENALTY_STATUS.NORMAL | BREACH_PENALTY_STATUS.PENALTY;
  isScheduled: boolean;
}

export interface IGetSalaryLogParams {
  skip: number;
  limit: number;
  salaryId: string;
}

export interface IGetSalaryLog {
  id: string;
  salaryId: string;
  editor: string;
  editorName: string;
  modifyType: EBatchScheduledCourseModifyType;
  modifyContent: {
    receivable: string;
  };
  remark: string;
  createdAt: string;
  updatedAt: string;
}

export interface IGetSalaryLogResponse {
  salaryLogs: IGetSalaryLog[];
  total: number;
}

export enum SALARY_COURSE_STATUS {
  FINISH = 'finish',
  OVER = 'over',
}
