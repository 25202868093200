import { EMONEY_TYPE } from 'constants/salary.constants';
import { Buffer } from 'buffer';
export const base64ToBlob = (data: string): Blob => {
  const byteString = atob(data);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab]);
};

export const formatSizeUnitsMB = (data: number): string => {
  return (data / (1024 * 1024)).toFixed(2);
};

export const formatNumberWithCommas = (number: number): string => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const encodeToBase64 = (text: string): string => {
  const encodedString = window.btoa(text);
  return encodedString;
};
export const decodeBase64 = (base64String: string): string => {
  const decodedBuffer = Buffer.from(base64String, 'base64');
  const decodedString = decodedBuffer.toString('utf8');
  return decodedString;
};

export const formatAmountInit = (amount: string): string => {
  return amount.startsWith('-') ? amount.substring(1) : amount;
};

export const formatAmount = (amount: string, type: EMONEY_TYPE): string => {
  return amount === '0'
    ? '0'
    : type === EMONEY_TYPE.PENALTY
    ? `-${amount}`
    : amount;
};

export const getReasonPunished = (reasonPunished: string) => {
  if (!reasonPunished.includes('-')) {
    return [reasonPunished, 'Lần 1'];
  }

  const [reason, time] = reasonPunished.split('-');

  return [reason, time];
};
