import { AxiosInstance } from 'axios';
export interface IGetExamsParams {
  page: number;
  page_size: number;
  region: string;
  eduSubject?: string;
}
export interface IGetExamsResponse {
  _id: string;
  title: string;
  description: string;
  region: string;
  eduSubject: string;
  totalScore: number;
  duration: number;
  questions: {
    question_id: string;
    score: number;
    order: number;
  }[];
  createdBy: string;
  isPublic: boolean;
  createdAt: string;
  updatedAt: string;
}
export default (axios: AxiosInstance, baseUrl: string) => ({
  getExams: async (params: IGetExamsParams) => {
    const response = await axios.get<IGetExamsResponse[]>(`${baseUrl}/exams`, {
      params,
    });
    return response.data;
  },
});
