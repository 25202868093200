import Decimal from 'decimal.js';

export const numberWithCommas = (x: number | string) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatVnCurrency = (
  value: number | string,
  includeCurrency = false,
) => {
  if (Number(value) === 0) return value.toString();

  const num = Number(value);
  if (isNaN(num)) return value.toString();

  // Multiply by 1000 to convert to VND format
  const convertedValue = num * 1000;

  const formatted = numberWithCommas(convertedValue.toFixed(0));

  return `${formatted}${includeCurrency ? ' VND' : ''}`;
};

export const convertDecimalToNumber = (value: string | undefined): number => {
  if (!value) return 0;
  return Decimal.add(parseInt(value), 0).toNumber();
};
